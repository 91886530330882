<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <b-card
      no-body
    >
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
            <b-card
                class="card-transaction"
                no-body
            >
                <b-card-header>
                <b-card-title>Transactions</b-card-title>
                </b-card-header>

                <b-card-body>
                <div
                    v-for="dist in distribution"
                    class="transaction-item"
                >
                    <b-media no-body>
                    <b-media-aside>
                        {{ dist.name }}
                    </b-media-aside>
                    </b-media>
                    <div class="font-weight-bolder text-success" >
                        {{ dist.quantity }} {{ dist.unit }}
                    </div>
                </div>
                </b-card-body>
            </b-card>
            <!--<b-img
                src="@/assets/images/icons/list.gif"
                alt="VicTsing Wireless Mouse"
                class="product-img"
                fluid
            />-->
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
            class="unstyled-list list-inline pl-1 border-left"
          >

            <!-- Product Name -->
            <h4>{{ $t('Form.Product.Product') }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
               <v-select
                    v-model="product"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="products"
                    @input="onChengeProduct()"
                    label="name"
                />
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
            <b-form-group
              label="Quantity"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                type="number"
                placeholder="1"
                v-model="quantity"
                class="unstyled-list list-inline pl-1 border-right"
              />
              <v-select
                v-model="unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="units"
                id="unit"
              />
            </b-form-group>
            </div>

            <!-- Avability -->
            <b-card-text>Available - <span class="text-success">In stock</span></b-card-text>
            <b-col
              cols="12"
              md="12"
            >
                <div class="chip closebtn" :style="selected === 'default' ? {'background-color': '#abdbe3'} : {'background-color': '#f1f1f1'}" @click="onChangeChip('default')">
                    <img src="@/assets/images/icons/storage.png" alt="Person" width="40" height="40">
                    Primary
                </div>
                <b-card-text v-for="(row, index) in distribution" :key="index">
                    <div class="chip closebtn" :style="selected === row.id ? {'background-color': '#abdbe3'} : {'background-color': '#f1f1f1'}" @click="onChangeChip(row.id)">
                        <img src="@/assets/images/icons/storage.png" alt="Person" width="40" height="40">
                        {{ row.name }}
                    </div>
                    
                </b-card-text>
            </b-col>
            <hr>
            <div class="product-color-options">
                <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="created"
                >
                    Execution
                </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import store from '@/store'
import {
  BForm, BInputGroup, BFormGroup, BFormInput, BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert, BBadge,
  BCardTitle, BCardHeader, BMediaAside, BMedia, BFormRadio
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moduleApi from '@/store/module'
import { getUserData } from '@/auth/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'


export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BForm, 
    BInputGroup, 
    BFormGroup,
    BFormInput,
    vSelect,
    BCardTitle,
    BCardHeader,
    BMediaAside,
    BMedia,
    BBadge,
    BFormRadio
  },
  data: () => ({
    product: '',
    quantity: '',
    products: [],
    unit: '',
    units: [],
    distribution: [],
    list: false,
    selected: 'default'
  }),
  methods: {
    fetchDataListProduct(idInstansi) { 
      moduleApi.fetchDataListProducs(idInstansi)
        .then(response => {       
            this.products  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },

    onChengeProduct() { 
      this.fetchDataListDistribution(this.product.id)
      this.list = true
    },

    onChangeChip(id) {
        this.selected = id
    },
    
    fetchDataListUnits() { 
      moduleApi.fetchDataListUnits()
        .then(response => {
            this.units = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },

    fetchDataListDistribution(id) {
      moduleApi.fetchDataListDistribution(id)
        .then(response => {
            this.distribution  = response.data.data            
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },

    created() { 
      moduleApi.consumtion({
        id_product: this.product.id,
        quantity: this.quantity,
        storage: this.selected
      })
        .then(response => {
            this.$router.replace({name: 'product'})
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  created () {
    const userData = getUserData()
    this.idInstansi = userData.idInstansi

    this.fetchDataListProduct(this.idInstansi)
    this.fetchDataListUnits()
  },
  setup() {
     const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.chip {
  display: inline-block;
  padding: 0 28px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  border-radius: 25px;
}

.chip img {
  float: left;
  margin: 0 10px 0 -25px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.closebtn {
  cursor: pointer;
}

.closebtn:hover {
  color: #000;
}
</style>